/* ./src/index.css */
@tailwind base;
@tailwind components;
@tailwind utilities;

:root{
  font-family: 'Ubuntu', sans-serif;
}

/* Scrollbar styles */
::-webkit-scrollbar {
  width: 6px;
  height: 10px;
  }
  
  ::-webkit-scrollbar-track {
  width: 2px;
  border: 1px dashed #29255c;
  }
  
  ::-webkit-scrollbar-thumb {
  background: #bfdbfe;
  border-radius: 25px;
  }
  
  ::-webkit-scrollbar-thumb:hover {
  background: #177EE8;
  }

  .css-1s2u09g-control, .css-319lph-ValueContainer, .css-1s2u09g-control{
    font-size: 13px;
  }

  #custom-file-upload-btn{
    background-color: indigo;
    color: white;
    padding: 0.2rem;
    cursor: pointer;
    font-size: smaller;
    width: 100%;
  }

  progress {
    border-radius: 5px;
    height: 10px;
    }
  progress::-webkit-progress-bar {
    border-radius: 5px;
  }
  progress::-webkit-progress-value {
    border-radius: 5px;
  }
  progress::-moz-progress-bar {
    border-radius: 5px;
  }

  .react-tel-input .form-control{
    width: 100% !important;
    height: 38px !important;
    border-radius: 2px !important;
  }

  input[type="text"] {
    text-transform: capitalize;
  }